import { gql } from '@apollo/client';
import { COMMON_LIST_ARGS, toGqlArgs, toGqlVariables } from '../utils';

const LIST_ARGS = {
  ...COMMON_LIST_ARGS,
  after: 'String',
  telecallerId: 'ID',
  status: 'LeadStatus',
  signupSource: 'SignupSourceType',
  examCategoryIds: '[ID!]',
  testType: 'TestTypes',
  testIds: '[ID!]',
  location: 'LocationInput',
  paymentStatus: 'PaymentStatus',
  unassignedLeads: 'Boolean',
};

const GET = gql`
  query GetLead($id: ID!) {
    lead(id: $id) {
      name
      mobile
      type
      status
      user {
        name
        mobile
        email
        signupSource
        createdAt
        payments(limit: 1) {
          nodes {
            status
          }
        }
        examCategories {
          name {
            en
          }
        }
        currentStreak
        longestStreak
        streakStats {
          testsAttemptCount
          questionsAttemptCount
          postsReadCount
          totalStudyTime
        }
      }
      history {
        status
        remarks
        date
        followUpDate
        updatedBy {
          id
          name
        }
      }
      assignHistory {
        telecaller {
          id
          name
        }
        date
      }
    }
  }
`;

const LIST = gql`
  query ListLeads${toGqlVariables(LIST_ARGS)} {
    items: leads${toGqlArgs(LIST_ARGS)} {
      nodes {
        id
        name
        mobile
        type
        user {
          name
          mobile
          signupSource
          location {
            city
            state
            country
          }
          isPlusMember
          payments(limit: 1) {
            nodes {
              status
              updatedAt
            }
          }
          streakStats {
            testsAttemptCount
          }
          examCategories {
            id
            name { en }
          }
        }
        telecaller {
          id
          name
        }
        status
        history(lastOnly: true) {
          status
          remarks
          followUpDate
          date
        }
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

const LIST_TELECALLER_LEADS = gql`
  query ListTelecallerLeads${toGqlVariables(LIST_ARGS)} {
    items: leads${toGqlArgs(LIST_ARGS)} {
      nodes {
        id
        name
        mobile
        type
        status
        user {
          name
          mobile
          payments(limit: 1) {
            nodes {
              status
            }
          }
          examCategories {
            name { en }
          }
          stats {
            testsAttemptCount
          }
        }
        updatedAt
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

const ASSIGN = gql`
  mutation AssignLead($input: AssignLeadInput!) {
    assignLead(input: $input) {
      success
    }
  }
`;

const APPROVE = gql`
  mutation ApproveLead($telecallerId: ID!) {
    approveLead(telecallerId: $telecallerId) {
      success
    }
  }
`;

const UPDATE = gql`
  mutation UpdateLead($input: UpdateLeadInput!) {
    updateLead(input: $input) {
      id
    }
  }
`;

const CREATE_REFERRAL = gql`
  mutation CreateReferralLead($input: [CreateReferralLeadInput!]!) {
    createReferralLeads(input: $input) {
      success
      message
    }
  }
`;

const CREATE_BY_CSV = gql`
  mutation CreateLeadsByCsv($file: Upload!) {
    upload: createLeadsByCsv(file: $file) {
      errors
    }
  }
`;

export {
  GET,
  LIST,
  LIST_TELECALLER_LEADS,
  ASSIGN,
  UPDATE,
  CREATE_REFERRAL,
  CREATE_BY_CSV,
  APPROVE,
};
