import { gql } from '@apollo/client';
import { COMMON_LIST_ARGS, COMMON_SEARCH_ARGS, toGqlArgs, toGqlVariables } from 'api/utils';

const GET = gql`
  query GetExpert($id: ID!) {
    expert(id: $id) {
      id
      name
      description
      socialLinks
      status
    }
  }
`;

const LIST = gql`
  query ListExperts${toGqlVariables(COMMON_LIST_ARGS)} {
    items: experts${toGqlArgs(COMMON_LIST_ARGS)} {
      nodes {
        id
        name
        status
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreateExpert($input: CreateExpertInput!) {
    createExpert(input: $input) {
      id
    }
  }
`;

const UPDATE = gql`
  mutation UpdateExpert($input: UpdateExpertInput!) {
    updateExpert(input: $input) {
      id
    }
  }
`;

const SEARCH = gql`
  query SearchExperts${toGqlVariables(COMMON_SEARCH_ARGS)} {
    items: experts${toGqlArgs(COMMON_SEARCH_ARGS)} {
      nodes {
        value: id
        label: name
      }
    }
  }
`;

export { GET, LIST, CREATE, UPDATE, SEARCH };
