import { useState, useCallback } from 'react';
import { DocumentNode, ApolloError } from '@apollo/client';
import { FormInstance } from 'antd';
import apolloClient from './apollo';
import { handleSuccess, handleError, setFormErrors } from './handlers';
import * as Auth from './apps/auth';
import * as Banner from './apps/banner';
import * as Chapter from './apps/chapter';
import * as Comment from './apps/comment';
import * as Evaluation from './apps/evaluation';
import * as Exam from './apps/exam';
import * as ExamCategory from './apps/examCategory';
import * as ExamTier from './apps/examTier';
import * as Expert from './apps/expert';
import * as Faq from './apps/faq';
import * as Rating from './apps/rating';
import * as Scholarship from './apps/scholarship';
import * as File from './apps/file';
import * as Instructions from './apps/instruction';
import * as Magazine from './apps/magazine';
import * as Notification from './apps/notification';
import * as Post from './apps/post';
import * as Question from './apps/question';
import * as Subject from './apps/subject';
import * as Test from './apps/test';
import * as TestAttempt from './apps/testAttempt';
import * as TestSeries from './apps/testseries';
import * as Ticket from './apps/ticket';
import * as Topic from './apps/topic';
import * as Trending from './apps/trending';
import * as User from './apps/user';
import * as Video from './apps/video';
import * as VideoPlaylist from './apps/videoPlaylist';
import * as Payment from './apps/payment';
import * as PlusSubscription from './apps/plusSubscription';
import * as ProblemReport from './apps/problemReport';
import * as PYPPdf from './apps/pyp-pdf';
import * as Concept from './apps/concept';
import * as Formula from './apps/formula';
import * as Book from './apps/book';
import * as Instructor from './apps/instructor';
import * as PracticeSet from './apps/practiceSet';
import * as PushNotification from './apps/pushNotification';
import * as Syllabus from './apps/syllabus';
import * as Subtopic from './apps/subtopic';
import * as ShortUrl from './apps/shortUrl';
import * as LiveClass from './apps/liveClass';
import * as Chat from './apps/chat';
import * as Batch from './apps/batch';
import * as Payout from './apps/payout';
import PromoCode from './apps/promoCode';
import * as EBook from './apps/eBook';
import * as NcertSolution from './apps/ncertSolution';
import * as Lead from './apps/lead';
import * as Telecaller from './apps/telecaller';
import * as Location from './apps/location';
import * as Topper from './apps/topper';
import * as Coupon from './apps/coupon';

enum APIType {
  Query = 'query',
  Mutation = 'mutation',
}

interface APIObject {
  type: ValueOf<APIType>;
  document: DocumentNode;
  success?: string;
}

export interface HookOptions {
  form?: FormInstance;
  notification?: string | boolean;
  successNotification?: string | boolean;
  errorNotitfication?: string | boolean;
}

export type Variables = PlainObject<any>;
export type ExecuteFn = (variables?: Variables, altSuccessDescription?: string) => Promise<any>;
type UseAPIResultState = { loading: boolean; data: any; error: ApolloError | null };
export type UseAPI = (opts?: HookOptions) => [ExecuteFn, UseAPIResultState];

function cleanData(data: any) {
  if (!data) return null;
  // omit null value and __typename key
  return JSON.parse(JSON.stringify(data), (key, val) =>
    key === '__typename' || val === null ? undefined : val,
  );
}

function getApiHook(apiObject: APIObject): UseAPI {
  return (opts: HookOptions = {}) => {
    const [state, setState] = useState<UseAPIResultState>({
      loading: false,
      data: null,
      error: null,
    });
    const {
      notification,
      successNotification = notification,
      errorNotitfication = notification,
      form,
    } = opts;

    const executeFn = useCallback(
      async (variables: Variables = {}, altSuccessDescription?: string) => {
        const { type, document, success } = apiObject;
        try {
          setState((prevState) => ({ ...prevState, loading: true }));
          const response = await (type === APIType.Mutation
            ? apolloClient.mutate({ variables, mutation: document })
            : apolloClient.query({
              variables,
              query: document,
              fetchPolicy: 'network-only',
            }));

          const data = cleanData(response.data);
          setState({ loading: false, data, error: null });
          handleSuccess(data, {
            notification: successNotification,
            description: altSuccessDescription ?? success,
          });
          return data;
        }
        catch (error: any) {
          setState({ loading: false, data: null, error });
          if (form) setFormErrors(error, form);
          if (errorNotitfication) handleError(error, { notification: errorNotitfication });
          throw error;
        }
      },
      [successNotification, errorNotitfication, form],
    );

    return [executeFn, state];
  };
}

const useApi = {
  Auth: {
    GoogleLogin: getApiHook({
      type: APIType.Mutation,
      document: Auth.GOOGLE_LOGIN,
    }),
    Logout: getApiHook({
      type: APIType.Mutation,
      document: Auth.LOGOUT,
    }),
  },
  User: {
    Get: getApiHook({
      type: APIType.Query,
      document: User.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: User.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: User.CREATE,
      success: 'New user created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: User.UPDATE,
      success: 'User updated successfully.',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: User.SEARCH,
    }),
    ListTestAttempts: getApiHook({
      type: APIType.Query,
      document: TestAttempt.LIST_USER_ATTEMPTS,
    }),
  },
  Test: {
    Get: getApiHook({
      type: APIType.Query,
      document: Test.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Test.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Test.CREATE,
      success: 'New Test created.',
    }),
    Clone: getApiHook({
      type: APIType.Mutation,
      document: Test.CLONE,
      success: 'Test Cloned sucessfully.',
    }),
    UpdateSettings: getApiHook({
      type: APIType.Mutation,
      document: Test.UPDATE_SETTINGS,
      success: 'Test settings updated sucessfully.',
    }),
    UpdateSections: getApiHook({
      type: APIType.Mutation,
      document: Test.UPDATE_SECTIONS,
      success: 'Test sections updated sucessfully.',
    }),
    AddQuestions: getApiHook({
      type: APIType.Mutation,
      document: Test.ADD_QUESTIONS,
      success: 'Questions added successfully.',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: Test.SEARCH,
    }),
    UploadQuestions: getApiHook({
      type: APIType.Mutation,
      document: Test.CREATE_BY_CSV,
    }),
    ListAttempts: getApiHook({
      type: APIType.Query,
      document: TestAttempt.LIST_TEST_ATTEMPTS,
    }),
    AnalyzeTest: getApiHook({
      type: APIType.Query,
      document: Test.ANALYZE_TEST,
    }),
    GenerateTest: getApiHook({
      type: APIType.Mutation,
      document: Test.GENERATE_TEST,
    }),
  },
  Expert: {
    Get: getApiHook({
      type: APIType.Query,
      document: Expert.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Expert.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Expert.CREATE,
      success: 'New expert created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Expert.UPDATE,
      success: 'Expert updated successfully.',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: Expert.SEARCH,
    }),
  },
  Post: {
    Get: getApiHook({
      type: APIType.Query,
      document: Post.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Post.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Post.CREATE,
      success: 'New post created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Post.UPDATE,
      success: 'Post updated successfully.',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: Post.SEARCH,
    }),
  },
  ExamCategory: {
    Get: getApiHook({
      type: APIType.Query,
      document: ExamCategory.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: ExamCategory.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: ExamCategory.CREATE,
      success: 'New exam category created.',
    }),
    Exams: getApiHook({
      type: APIType.Query,
      document: ExamCategory.EXAMS,
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: ExamCategory.UPDATE,
      success: 'Exam Category updated successfully.',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: ExamCategory.SEARCH,
    }),
  },
  Exam: {
    Get: getApiHook({
      type: APIType.Query,
      document: Exam.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Exam.LIST,
    }),
    ListExamsAndCategories: getApiHook({
      type: APIType.Query,
      document: Exam.LIST_EXAMS_AND_CATEGORIES,
    }),
    ListNames: getApiHook({
      type: APIType.Query,
      document: Exam.LIST_NAMES,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Exam.CREATE,
      success: 'New exam created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Exam.UPDATE,
      success: 'Exam updated successfully.',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: Exam.SEARCH,
    }),
  },
  Evaluation: {
    Get: getApiHook({
      type: APIType.Query,
      document: Evaluation.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Evaluation.LIST,
    }),
    Approve: getApiHook({
      type: APIType.Mutation,
      document: Evaluation.APPROVE,
      success: 'Evaluation Approved',
    }),
    Reject: getApiHook({
      type: APIType.Mutation,
      document: Evaluation.REJECT,
      success: 'Evaluation Rejectd',
    }),
  },
  Subject: {
    Get: getApiHook({
      type: APIType.Query,
      document: Subject.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Subject.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Subject.CREATE,
      success: 'New subject created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Subject.UPDATE,
      success: 'Subject updated successfully.',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: Subject.SEARCH,
    }),
  },
  Topic: {
    Get: getApiHook({
      type: APIType.Query,
      document: Topic.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Topic.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Topic.CREATE,
      success: 'New topic created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Topic.UPDATE,
      success: 'Topic updated successfully.',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: Topic.SEARCH,
    }),
  },
  Question: {
    Get: getApiHook({
      type: APIType.Query,
      document: Question.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Question.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Question.CREATE,
      success: 'New question created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Question.UPDATE,
      success: 'Question updated successfully.',
    }),
    Upload: getApiHook({
      type: APIType.Mutation,
      document: Question.CREATE_BY_CSV,
    }),
    UpdateLanguageContent: getApiHook({
      type: APIType.Mutation,
      document: Question.UPDATE_LANGUAGE_CONTENT_BY_CSV,
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: Question.SEARCH,
    }),
    ListWithParent: getApiHook({
      type: APIType.Query,
      document: Question.LIST_WITH_PARENT,
    }),
    Count: getApiHook({
      type: APIType.Query,
      document: Question.COUNT,
    }),
    Translate: getApiHook({
      type: APIType.Mutation,
      document: Question.TRANSLATE,
    }),
  },
  ProblemReport: {
    Get: getApiHook({
      type: APIType.Query,
      document: ProblemReport.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: ProblemReport.LIST,
    }),
    Resolve: getApiHook({
      type: APIType.Mutation,
      document: ProblemReport.RESOLVE,
      success: 'Problem Report resolved successfully.',
    }),
  },
  Rating: {
    List: getApiHook({
      type: APIType.Query,
      document: Rating.LIST,
    }),
  },
  Scholarship: {
    List: getApiHook({
      type: APIType.Query,
      document: Scholarship.LIST,
    }),
  },
  Instructions: {
    Get: getApiHook({
      type: APIType.Query,
      document: Instructions.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Instructions.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Instructions.CREATE,
      success: 'New instruction template created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Instructions.UPDATE,
      success: 'Instruction template updated successfully.',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: Instructions.SEARCH,
    }),
  },
  TestSeries: {
    Get: getApiHook({
      type: APIType.Query,
      document: TestSeries.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: TestSeries.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: TestSeries.CREATE,
      success: 'New test series created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: TestSeries.UPDATE,
      success: 'Test Series updated successfully.',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: TestSeries.SEARCH,
    }),
  },
  Notification: {
    Get: getApiHook({
      type: APIType.Query,
      document: Notification.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Notification.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Notification.CREATE,
      success: 'New Notification created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Notification.UPDATE,
      success: 'Notification updated successfully.',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: Notification.SEARCH,
    }),
  },
  PushNotification: {
    Get: getApiHook({
      type: APIType.Query,
      document: PushNotification.GET,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: PushNotification.CREATE,
      success: 'New Push Notification created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: PushNotification.UPDATE,
      success: 'Push Notification updated successfully.',
    }),
    List: getApiHook({
      type: APIType.Query,
      document: PushNotification.LIST,
    }),
    Test: getApiHook({
      type: APIType.Mutation,
      document: PushNotification.TEST,
      success: 'Test Push Notification Sent',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: PushNotification.SEARCH,
    }),
  },
  File: {
    Upload: getApiHook({
      type: APIType.Mutation,
      document: File.UPLOAD,
      success: 'File uploaded successfully.',
    }),
    UploadFilePart: getApiHook({
      type: APIType.Mutation,
      document: File.UPLOAD_FILE_PART,
    }),
    CancelMultiUpload: getApiHook({
      type: APIType.Mutation,
      document: File.CANCEL_MULTI_PART_UPLOAD,
      success: 'File upload cancelled',
    }),
  },
  Faq: {
    Get: getApiHook({
      type: APIType.Query,
      document: Faq.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Faq.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Faq.CREATE,
      success: 'New FAQ created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Faq.UPDATE,
      success: 'FAQ updated successfully.',
    }),
    Delete: getApiHook({
      type: APIType.Mutation,
      document: Faq.DELETE,
      success: 'FAQ deleted successfully.',
    }),
  },
  Ticket: {
    Get: getApiHook({
      type: APIType.Query,
      document: Ticket.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Ticket.LIST,
    }),
    Resolve: getApiHook({
      type: APIType.Mutation,
      document: Ticket.RESOLVE,
      success: 'Ticket resolved successfully.',
    }),
    Reply: getApiHook({
      type: APIType.Mutation,
      document: Ticket.REPLY,
      success: 'Email sent successfully.',
    }),
  },
  Trending: {
    Get: getApiHook({
      type: APIType.Query,
      document: Trending.GET,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Trending.CREATE,
      success: 'Trending List updated successfully.',
    }),
  },
  Banner: {
    Get: getApiHook({
      type: APIType.Query,
      document: Banner.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Banner.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Banner.CREATE,
      success: 'New Banner created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Banner.UPDATE,
      success: 'Banner updated successfully.',
    }),
  },
  Video: {
    Get: getApiHook({
      type: APIType.Query,
      document: Video.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Video.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Video.CREATE,
      success: 'New Video created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Video.UPDATE,
      success: 'Video updated successfully.',
    }),
    GetURL: getApiHook({
      type: APIType.Query,
      document: Video.GET_VIDEO_URL,
    }),
    DownloadYoutubeVideo: getApiHook({
      type: APIType.Mutation,
      document: Video.DOWNLOAD_YOUTUBE_VIDEO,
      success: 'Video Info fetch successfully.',
    }),
    SyncVideoStats: getApiHook({
      type: APIType.Mutation,
      document: Video.SYNC_STATS,
      success: 'Video stats synced successfully.',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: Video.SEARCH,
    }),
  },
  VideoPlaylist: {
    Get: getApiHook({
      type: APIType.Query,
      document: VideoPlaylist.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: VideoPlaylist.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: VideoPlaylist.CREATE,
      success: 'New VideoPlaylist created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: VideoPlaylist.UPDATE,
      success: 'VideoPlaylist updated successfully.',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: VideoPlaylist.SEARCH,
    }),
  },
  Comment: {
    Hide: getApiHook({
      type: APIType.Mutation,
      document: Comment.HIDE,
      success: 'Comment status : HIDDEN ',
    }),
    Unhide: getApiHook({
      type: APIType.Mutation,
      document: Comment.UNHIDE,
      success: 'Comment status : VISIBLE',
    }),
  },
  Concept: {
    Get: getApiHook({
      type: APIType.Query,
      document: Concept.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Concept.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Concept.CREATE,
      success: 'New Concept Created',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Concept.UPDATE,
      success: 'Concept updated successfully',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: Concept.SEARCH,
    }),
  },
  Formula: {
    Get: getApiHook({
      type: APIType.Query,
      document: Formula.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Formula.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Formula.CREATE,
      success: 'New Formula Created',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Formula.UPDATE,
      success: 'Formula updated successfully',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: Formula.SEARCH,
    }),
  },
  Book: {
    Get: getApiHook({
      type: APIType.Query,
      document: Book.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Book.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Book.CREATE,
      success: 'New Book Created',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Book.UPDATE,
      success: 'Book updated successfully',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: Book.SEARCH,
    }),
  },
  Instructor: {
    Get: getApiHook({
      type: APIType.Query,
      document: Instructor.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Instructor.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Instructor.CREATE,
      success: 'New instructor created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Instructor.UPDATE,
      success: 'Instructor updated successfully.',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: Instructor.SEARCH,
    }),
  },
  PracticeSet: {
    Get: getApiHook({
      type: APIType.Query,
      document: PracticeSet.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: PracticeSet.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: PracticeSet.CREATE,
      success: 'New PracticeSet created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: PracticeSet.UPDATE,
      success: 'Practice Set updated successfully.',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: PracticeSet.SEARCH,
    }),
  },
  Subtopic: {
    Get: getApiHook({
      type: APIType.Query,
      document: Subtopic.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Subtopic.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Subtopic.CREATE,
      success: 'New subtopic created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Subtopic.UPDATE,
      success: 'Subtopic updated successfully.',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: Subtopic.SEARCH,
    }),
  },
  Syllabus: {
    GetSubjectsTopics: getApiHook({
      type: APIType.Query,
      document: Syllabus.GET_SUBJECTS_TOPICS,
    }),
    GetLectures: getApiHook({
      type: APIType.Query,
      document: Syllabus.GET_LECTURES,
    }),
    GetNotes: getApiHook({
      type: APIType.Query,
      document: Syllabus.GET_NOTES,
    }),
    GetQuizzes: getApiHook({
      type: APIType.Query,
      document: Syllabus.GET_QUIZZES,
    }),
    UpdateOrder: getApiHook({
      type: APIType.Mutation,
      document: Syllabus.UPDATE_ORDER,
      success: 'Syllabus order updated successfully.',
    }),
  },
  ShortUrl: {
    Create: getApiHook({
      type: APIType.Mutation,
      document: ShortUrl.CREATE,
      success: 'Short URL created successfully.',
    }),
  },
  Chapter: {
    Get: getApiHook({
      type: APIType.Query,
      document: Chapter.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Chapter.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Chapter.CREATE,
      success: 'New Chapter created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Chapter.UPDATE,
      success: 'Chapter updated successfully.',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: Chapter.SEARCH,
    }),
  },
  ExamTier: {
    Get: getApiHook({
      type: APIType.Query,
      document: ExamTier.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: ExamTier.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: ExamTier.CREATE,
      success: 'New Exam Tier created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: ExamTier.UPDATE,
      success: 'Exam Tier updated successfully.',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: ExamTier.SEARCH,
    }),
  },
  Magazine: {
    Get: getApiHook({
      type: APIType.Query,
      document: Magazine.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Magazine.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Magazine.CREATE,
      success: 'New magazine created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Magazine.UPDATE,
      success: 'Magazine updated successfully.',
    }),
  },
  PYPPdf: {
    Get: getApiHook({
      type: APIType.Query,
      document: PYPPdf.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: PYPPdf.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: PYPPdf.CREATE,
      success: 'New PYP PDF created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: PYPPdf.UPDATE,
      success: 'PYP PDF updated successfully.',
    }),
  },
  LiveClass: {
    Get: getApiHook({
      type: APIType.Query,
      document: LiveClass.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: LiveClass.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: LiveClass.CREATE,
      success: 'New Live Class created.',
    }),
    UploadPdf: getApiHook({
      type: APIType.Mutation,
      document: LiveClass.UPLOAD_PDF,
      success: 'Pdf uploaded successfully.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: LiveClass.UPDATE,
      success: 'Live Class updated successfully.',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: LiveClass.SEARCH,
    }),
    GetCanvasData: getApiHook({
      type: APIType.Query,
      document: LiveClass.GET_CANVAS_DATA,
    }),
    GetSlideData: getApiHook({
      type: APIType.Query,
      document: LiveClass.GET_SLIDE_DATA,
    }),
    Start: getApiHook({
      type: APIType.Mutation,
      document: LiveClass.START_CLASS,
    }),
    SetChatStatus: getApiHook({
      type: APIType.Mutation,
      document: LiveClass.SET_CHAT_STATUS,
    }),
    BlockUser: getApiHook({
      type: APIType.Mutation,
      document: LiveClass.BLOCK_USER,
    }),
    EndClass: getApiHook({
      type: APIType.Mutation,
      document: LiveClass.END_CLASS,
    }),
    PinMessage: getApiHook({
      type: APIType.Mutation,
      document: LiveClass.PIN_MESSAGE,
    }),
    StartContainer: getApiHook({
      type: APIType.Mutation,
      document: LiveClass.START_CONTAINER,
    }),
    ClassInstructorUserId: getApiHook({
      type: APIType.Query,
      document: LiveClass.CLASS_INSTRUCTOR_USER_ID,
    }),
  },
  Chat: {
    Get: getApiHook({
      type: APIType.Query,
      document: Chat.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Chat.LIST,
    }),
  },
  Batch: {
    Get: getApiHook({
      type: APIType.Query,
      document: Batch.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Batch.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Batch.CREATE,
      success: 'New Batch created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Batch.UPDATE,
      success: 'Batch updated successfully.',
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: Batch.SEARCH,
    }),
  },
  Payment: {
    List: getApiHook({
      type: APIType.Query,
      document: Payment.LIST,
    }),
    CreatePaymentLink: getApiHook({
      type: APIType.Mutation,
      document: Payment.CREATE_PAYMENT_LINK,
      success: 'Payment link created successfully.',
    }),
  },
  PlusSubscription: {
    List: getApiHook({
      type: APIType.Query,
      document: PlusSubscription.LIST,
    }),
  },
  Payout: {
    Get: getApiHook({
      type: APIType.Query,
      document: Payout.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Payout.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Payout.CREATE,
      success: 'Redeem Request Created',
    }),
    GetRedeemData: getApiHook({
      type: APIType.Query,
      document: Payout.GET_REDEEM_DATA,
    }),
    CreateBeneficiary: getApiHook({
      type: APIType.Mutation,
      document: Payout.CREATE_BENEFICIARY,
    }),
  },
  PromoCode: {
    List: getApiHook({
      type: APIType.Query,
      document: PromoCode,
    }),
  },
  EBook: {
    Get: getApiHook({
      type: APIType.Query,
      document: EBook.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: EBook.LIST,
    }),
  },
  NcertSolution: {
    Get: getApiHook({
      type: APIType.Query,
      document: NcertSolution.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: NcertSolution.LIST,
    }),
  },
  Lead: {
    Get: getApiHook({
      type: APIType.Query,
      document: Lead.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Lead.LIST,
    }),
    ListTelecallerLeads: getApiHook({
      type: APIType.Query,
      document: Lead.LIST_TELECALLER_LEADS,
    }),
    Assign: getApiHook({
      type: APIType.Mutation,
      document: Lead.ASSIGN,
      success: 'Leads updated successfully.',
    }),
    Approve: getApiHook({
      type: APIType.Mutation,
      document: Lead.APPROVE,
      success: 'Leads Approved successfully.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Lead.UPDATE,
      success: 'Lead status updated successfully.',
    }),
    CreateReferral: getApiHook({
      type: APIType.Mutation,
      document: Lead.CREATE_REFERRAL,
    }),
    Upload: getApiHook({
      type: APIType.Mutation,
      document: Lead.CREATE_BY_CSV,
    }),
  },
  Telecaller: {
    Stats: getApiHook({
      type: APIType.Query,
      document: Telecaller.STATS,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Telecaller.LIST,
    }),
    Search: getApiHook({
      type: APIType.Query,
      document: Telecaller.SEARCH,
    }),
  },
  Location: {
    Search: getApiHook({
      type: APIType.Query,
      document: Location.SEARCH,
    }),
  },
  Topper: {
    Get: getApiHook({
      type: APIType.Query,
      document: Topper.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Topper.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Topper.CREATE,
      success: 'New Topper created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Topper.UPDATE,
      success: 'Topper updated successfully.',
    }),
  },
  Coupon: {
    Get: getApiHook({
      type: APIType.Query,
      document: Coupon.GET,
    }),
    List: getApiHook({
      type: APIType.Query,
      document: Coupon.LIST,
    }),
    Create: getApiHook({
      type: APIType.Mutation,
      document: Coupon.CREATE,
      success: 'New coupon created.',
    }),
    Update: getApiHook({
      type: APIType.Mutation,
      document: Coupon.UPDATE,
      success: 'Coupon updated successfully.',
    }),
  },
};

export * from './apollo';
export * from './handlers';
export default useApi;
